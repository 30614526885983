<template>
  <div class="px-3 py-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/logo/logo.png" alt="logo" width="80" />
        <span class="title-logo"> Smartwork </span>
      </div>

      <b-link
        to="/hrm/employee"
        class="d-flex align-items-center bg-white p-1 rounded-pill cursor-pointer"
      >
        <span class="bold">&#10006; Close</span>
      </b-link>
    </div>

    <form @submit.prevent="submit_form">
      <div v-if="compOne">
        <div class="text-center mt-3">
          <h2 style="color: #1e1e1e">Empower Your Team</h2>
          <span class="text-muted">
            Add new people to grow the company reaching their goals
          </span>
        </div>

        <div class="d-flex justify-content-center">
          <b-card class="mt-2 w-40 rounded-3">
            <b-form-group label="Complete Name">
              <b-form-input
                v-model="fullname"
                placeholder="John Doe"
                autocomplete="off"
                required
              />
            </b-form-group>
            <b-form-group label="Email Address">
              <b-form-input
                v-model="email"
                type="email"
                placeholder="john@mail.com"
                autocomplete="off"
                required
              />
            </b-form-group>
            <b-form-group label="Gender">
              <b-form-select
                id="gender"
                v-model="gender"
                :options="genderOptions"
                autocomplete="off"
                required
              />
            </b-form-group>
            <b-form-group label="Phone Number">
              <b-form-input
                v-model="phone"
                type="number"
                placeholder="08123456789"
                autocomplete="off"
                required
              />
            </b-form-group>
            <b-form-group label="Employee Type">
              <b-form-select
                id="employee_type"
                v-model="employee_type"
                :options="employee_typeOptions"
                autocomplete="off"
                required
              />
            </b-form-group>
            <b-form-group label="Branch">
              <b-form-select
                id="branch"
                v-model="branch"
                :options="branch_options"
                autocomplete="off"
                required
              />
            </b-form-group>
            <div class="text-right mt-2">
              <b-button
                type="button"
                variant="primary"
                class="rounded-pill"
                @click="next('one')"
              >
                Continue
                <feather-icon icon="ArrowRightIcon" size="13"></feather-icon>
              </b-button>
            </div>
          </b-card>
        </div>
      </div>

      <div v-if="compTwo">
        <div class="text-center mt-3">
          <h2 style="color: #1e1e1e">Assign to Department</h2>
          <span class="text-muted">
            Complete the information to make it more easier when introduce to
            team
          </span>
        </div>

        <div class="d-flex justify-content-center">
          <b-card class="mt-2 w-40 rounded-3">
            <b-form-group label="Department">
              <v-select
                v-model="department"
                :options="departmentOptions"
                @input="get_responsibilities"
                label="title"
              >
                <template #option="{ title, icon }">
                  <feather-icon
                    :icon="icon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ title }}</span>
                </template>
              </v-select>
            </b-form-group>

            <div v-if="show_responsibility">
              <label>Responsibilities</label>
              <div
                class="d-flex align-items-center"
                v-for="responsibility in responsibilities"
                :key="responsibility.id"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  size="13"
                  class="text-success"
                />
                <span style="margin-left: 5px">{{ responsibility.title }}</span>
              </div>
            </div>

            <div class="text-right mt-2">
              <b-button
                type="button"
                variant="outline-primary"
                class="rounded-pill mr-1"
                @click="back('one')"
              >
                <feather-icon icon="ArrowLeftIcon" size="13"></feather-icon>
                Back
              </b-button>
              <b-button
                type="button"
                variant="primary"
                class="rounded-pill"
                @click="next('two')"
              >
                Continue
                <feather-icon icon="ArrowRightIcon" size="13"></feather-icon>
              </b-button>
            </div>
          </b-card>
        </div>
      </div>

      <div v-if="compThree">
        <div class="text-center mt-3">
          <h2 style="color: #1e1e1e">Job Level & Direct Manager</h2>
          <span class="text-muted">
            Complete the information to make it more easier when introduce to
            team
          </span>
        </div>

        <div class="d-flex justify-content-center">
          <b-card class="mt-2 w-40 rounded-3">
            <b-form-group label="Job Level">
              <v-select
                v-model="job_level"
                :options="jobLevelOptions"
                label="title"
              >
                <template #option="{ title, icon }">
                  <feather-icon
                    :icon="icon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ title }}</span>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group label="Manager Name">
              <v-select
                v-model="direct_manager"
                :options="managerOptions"
                label="title"
              >
                <template #option="{ title, icon }">
                  <feather-icon
                    :icon="icon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ title }}</span>
                </template>
              </v-select>
            </b-form-group>

            <div class="text-right mt-2">
              <b-button
                type="button"
                variant="outline-primary"
                class="rounded-pill mr-1"
                @click="back('two')"
              >
                <feather-icon icon="ArrowLeftIcon" size="13"></feather-icon>
                Back
              </b-button>
              <b-button
                type="button"
                variant="primary"
                class="rounded-pill"
                @click="next('three')"
              >
                Continue
                <feather-icon icon="ArrowRightIcon" size="13"></feather-icon>
              </b-button>
            </div>
          </b-card>
        </div>
      </div>

      <div v-if="compFour">
        <div class="text-center mt-3">
          <h2 style="color: #1e1e1e">Assign a Position</h2>
          <span class="text-muted">
            Complete the information to make it more easier when introduce to
            team
          </span>
        </div>

        <div class="d-flex justify-content-center">
          <b-card class="mt-2 w-35 rounded-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-avatar src="" size="50" />
                <div class="ml-1" style="line-height: 10px; margin-bottom: 4px">
                  <h5>{{ fullname }}</h5>
                  <span class="text-muted">{{ email }}</span>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <span class="ml-1 text-muted"
                  >{{ job_level.title }}, {{ department.title }}</span
                >
              </div>
            </div>
          </b-card>
        </div>

        <div class="px-5">
          <div class="d-flex justify-content-between mt-3">
            <div class="text-left">
              <h5 style="color: #1e1e1e">Positions</h5>
              <span class="text-muted"> Improve your growth </span>
            </div>

            <div>
              <input
                type="search"
                v-model="searchPosition"
                @input="handle_search()"
                class="form-control"
                placeholder="Search Position"
              />
            </div>
          </div>

          <b-row class="mt-2">
            <b-col
              md="3"
              v-for="position in positions"
              :key="position.id"
              @click="select_position(position)"
              class="cursor-pointer"
            >
              <b-card
                class="rounded-3"
                :class="
                  position_selected == position.id ? 'position-active' : ''
                "
              >
                <b-card-body align="center">
                  <b-avatar :src="position.icon" size="50"></b-avatar>
                  <!-- </div> -->
                  <b-card-text class="mt-2">
                    <h5>{{ position.name }}</h5>
                    <span>people assigned</span>
                  </b-card-text>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <div class="d-flex justify-content-between align-items-center">
            <span>
              Showing
              {{ perPage > totalRows ? totalRows : perPage }} of
              {{ totalRows }} Positions
            </span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
              @change="pageChange"
            />
          </div>
        </div>
      </div>
      <div
        class="text-right mt-2"
        v-if="position_selected != null && compThree == false"
      >
        <b-button
          type="button"
          variant="outline-primary"
          class="rounded-pill mr-1"
          @click="back('three')"
        >
          <feather-icon icon="ArrowLeftIcon" size="13"></feather-icon>
          Back
        </b-button>
        <b-button type="submit" variant="primary" class="rounded-pill">
          <feather-icon icon="SaveIcon" size="13"></feather-icon>
          Submit
        </b-button>
      </div>
    </form>
  </div>
</template>

<style>
.title-logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: #423ffd;
  margin-left: 0.5rem;
}

.rounded-3 {
  border-radius: 1.75rem;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

legend {
  font-weight: 500;
  color: #8f8f8f;
}

.col-form-label {
  font-size: 12px;
}

.form-control {
  border-radius: 1.75rem;
}

.custom-select {
  border-radius: 1.75rem;
}

.position-active {
  border: 1px solid #423ffd;
  position: relative;
}

.position-active::after {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: #423ffd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import { debounce } from "lodash";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {},
  data() {
    return {
      // Component one
      fullname: "",
      email: "",
      phone: "",
      gender: "select option",
      genderOptions: [
        { value: "select option", text: "Select Gender" },
        { value: "MALE", text: "Male" },
        { value: "FEMALE", text: "Female" },
      ],
      employee_type: "select option",
      employee_typeOptions: [],
      branch: "select option",
      branch_options: [],

      // Component two
      department: "",
      departmentOptions: [],
      responsibilities: [],
      show_responsibility: false,

      // Component three
      position: "",
      positions: [],
      position_selected: null,

      // Components
      compOne: true,
      compTwo: false,
      compThree: false,
      compFour: false,
      compFive: false,
      perPage: 8,
      totalRows: 1,
      currentPage: 1,
      searchPosition: "",

      // Form
      last_user_id: 0,
      managerOptions: [],
      direct_manager: "",
      jobLevelOptions: [],
      job_level: "",
    };
  },
  mounted() {
    this.get_department();
    this.get_position();
    this.get_employee_type();
    this.get_branch();
    this.get_users();
    this.get_job_level();
  },

  methods: {
    next(slide) {
      if (slide === "one") {
        // validate form component one
        if (this.fullname == "" || this.email == "" || this.phone == 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "AlertTriangleIcon",
              text: "Please fill all the form before continue",
              variant: "warning",
            },
          });

          return;
        }

        // change component
        this.compOne = false;
        this.compTwo = true;
        this.compThree = false;
        this.compFour = false;
        this.compFive = false;
      } else if (slide === "two") {
        // validate form component two
        if (this.fullname == "" || this.email == "" || this.phone == 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "AlertTriangleIcon",
              text: "Please fill all the form before continue",
              variant: "warning",
            },
          });

          return;
        }

        // change component
        this.compOne = false;
        this.compTwo = false;
        this.compThree = true;
        this.compFour = false;
        this.compFive = false;
      } else if (slide === "three") {
        // validate form component three
        if (this.fullname == "" || this.email == "" || this.phone == 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "AlertTriangleIcon",
              text: "Please fill all the form before continue",
              variant: "warning",
            },
          });

          return;
        }

        // change component
        this.compOne = false;
        this.compTwo = false;
        this.compThree = false;
        this.compFour = true;
        this.compFive = false;
      } else if (slide === "four") {
        // validate form component four
        if (this.fullname == "" || this.email == "" || this.phone == 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "AlertTriangleIcon",
              text: "Please fill all the form before continue",
              variant: "warning",
            },
          });

          return;
        }

        // change component
        this.compOne = false;
        this.compTwo = false;
        this.compThree = false;
        this.compFour = false;
        this.compFive = true;
      } else if (slide === "five") {
        // validate form component five
        if (this.fullname == "" || this.email == "" || this.phone == 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "AlertTriangleIcon",
              text: "Please fill all the form before continue",
              variant: "warning",
            },
          });

          return;
        }

        // change component
      }
    },

    back(slide) {
      if (slide === "one") {
        // change component
        this.compOne = true;
        this.compTwo = false;
        this.compThree = false;
        this.compFour = false;
        this.compFive = false;
      } else if (slide === "two") {
        // change component
        this.compOne = false;
        this.compTwo = true;
        this.compThree = false;
        this.compFour = false;
        this.compFive = false;
      } else if (slide === "three") {
        // change component
        this.compOne = false;
        this.compTwo = false;
        this.compThree = true;
        this.compFour = false;
        this.compFive = false;
      } else if (slide === "four") {
        // change component
        this.compOne = false;
        this.compTwo = false;
        this.compThree = false;
        this.compFour = true;
        this.compFive = false;
      } else if (slide === "five") {
        // change component
        this.compOne = false;
        this.compTwo = false;
        this.compThree = false;
        this.compFour = false;
        this.compFive = true;
      }
    },

    get_job_level() {
      this.callApi({
        method: "GET",
        url: "/job_levels/fetch",
        success: (res) => {
          res.result.map((item) => {
            this.jobLevelOptions.push({
              icon: "ChevronsRightIcon",
              title: item.name,
              id: item.id,
            });
          });
        },
      });
    },

    get_users() {
      this.callApi({
        method: "GET",
        url: "/users/getAll",
        params: {
          limit: 100,
        },
        success: (res) => {
          res.result.data.map((item) => {
            this.managerOptions.push({
              icon: "ChevronsRightIcon",
              title: item.name,
              id: item.id,
            });
          });

          // except
          this.managerOptions = this.managerOptions.filter((item) => {
            return item.id != 1;
          });
        },
      });
    },

    get_department() {
      this.callApi({
        method: "GET",
        url: "/departments/fetch",
        params: {
          with_responsibilities: true,
        },
        success: (res) => {
          res.result.map((item) => {
            this.departmentOptions.push({
              icon: "ChevronsRightIcon",
              title: item.name,
              id: item.id,
            });
          });
        },
      });
    },

    get_responsibilities() {
      this.callApi({
        method: "GET",
        url: "/responsibilities/fetch",
        params: {
          department_id: this.hashid(this.department.id),
        },
        success: (res) => {
          this.responsibilities = [];

          res.result.map((item) => {
            this.responsibilities.push({
              title: item.name,
              id: item.id,
            });
          });

          if (this.responsibilities.length > 0) {
            this.show_responsibility = true;
          } else {
            this.show_responsibility = false;
          }
        },
      });
    },

    handle_search: debounce(function () {
      this.get_position();
    }, 500),

    get_position() {
      this.callApi({
        method: "GET",
        url: "/teams/fetch",
        params: {
          limit: 4,
          name: this.searchPosition,
        },
        success: (response) => {
          this.positions = response.result.data;
          this.totalRows = response.result.total;
          this.currentPage = response.result.current_page;
          this.perPage = response.result.per_page;
        },
      });
    },

    pageChange(page) {
      this.callApi({
        method: "GET",
        url: "/teams/fetch",
        params: {
          limit: 4,
          page: page,
        },
        success: (response) => {
          this.positions = response.result.data;
          this.totalRows = response.result.total;
          this.currentPage = response.result.current_page;
          this.perPage = response.result.per_page;
        },
      });
    },

    select_position(data) {
      this.position_selected = data.id;
    },

    get_employee_type() {
      this.callApi({
        method: "GET",
        url: "/employee_types/fetch",
        success: (res) => {
          res.result.map((item) => {
            this.employee_typeOptions.push({
              text: item.name,
              value: item.code,
            });
          });
        },
      });
    },

    get_branch() {
      this.callApi({
        method: "GET",
        url: "/branches/fetch",
        success: (res) => {
          res.result.data.map((item) => {
            this.branch_options.push({
              text: item.name,
              value: item.code,
            });
          });

          this.last_user_id = res.result.last_user_id;
        },
      });
    },

    submit_form() {
      this.callApi({
        method: "POST",
        url: "/users/register",
        data: {
          name: this.fullname,
          manager_id: this.direct_manager.id,
          email: this.email,
          phone: this.phone,
          department_id: this.department.id,
          team_id: this.position_selected,
          password: "Smartwork123#",
          hrcode: `${this.last_user_id}${this.employee_type}${this.branch}`,
          branch_id: this.branch,
          job_level_id: this.job_level.id,
        },
        success: (res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckCircleIcon",
              text: "Employee has been added",
              variant: "success",
            },
          });

          this.$router.push("/hrm/employee");
        },
      });
    },
  },
};
</script>
